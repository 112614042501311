/*Font Family Used In Website*/
/*Inter (Integrated From Google Fonts Library)*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;200;300;400;500;600;700;800;900&display=swap');


 .inter {
    font-family: "VT323";

 }

input {
    font-family: "VT323";
}

body .container {
 	width: 100%;
 	max-width: 1100px;
 font-family: "VT323";
}
.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.col-white {
	color:white !important;
}

.m-b-10 {
	margin-bottom: 10px !important
}
.m-b-20 {
	margin-bottom: 20px !important
}
.m-b-30 {
	margin-bottom: 30px !important
}

.modal-dialog {
    top: 50%;
    margin: 0px auto !important;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%) !important;
}

.body-wrapper {
    float: left;
    width: 100%;
    background: #008080;
    box-shadow: inset -1.00417px -1.00417px 0px #000000, inset 1.00417px 1.00417px 0px #dbdbdb, inset -2.00833px -2.00833px 0px #808080, inset 2.00833px 2.00833px 0px #ffffff;
}

.block-element {
	float: left;
	width: 100%;
}

.custom-block1 {
    float: left;
    width: 100%;
    background: #C3C3C3;
    box-shadow: inset -2.00833px -2.00833px 0px #262626, inset 2.00833px 2.00833px 0px #f0f0f0, inset -4.01667px -4.01667px 0px #7e7e7e;
}
.custom-block2 {
    float: left;
    width: 100%;
    background: #bfbfbf;
    box-shadow: inset -1.00417px -1.00417px 0px #000000, inset 1.00417px 1.00417px 0px #dbdbdb, inset -2.00833px -2.00833px 0px #808080, inset 2.00833px 2.00833px 0px #ffffff;
}

.sec-head {
    float: left;
    width: 99.6%;
    background: linear-gradient(270deg, #1085D2 0%, #00007B 100%);
    padding: 0px 30px;
    margin-left: 0.2%;
    margin-top: 2px;
}
.sec-head h1 {
    margin: 0px 0px 0px 0px;
    font-size: 30px;
    letter-spacing: 1.5px;
}

.content-block1 {
    float: left;
    width: 100%;
    padding: 40px 60px;
}

.textual-block1 {
    float: right;
    width: 100%;
    margin-bottom: 0px;
    padding: 30px;
    text-align: center;
}
.textual-block1 h4 {
    margin: 0px 0px 10px 0px;
    font-weight: 300;
    letter-spacing: 0.2px;
}
.textual-block1 p {
    margin: 0px;
    letter-spacing: 0.4px;
}

.form-field1 {
    float: left;
    width: 100%;
    margin-bottom: 25px;
}
.field-style1 {
    width: 100%;
    background: #ffffff;
    box-shadow: inset 2.00833px 2.00833px 0px #262626, inset -2.00833px -2.00833px 0px #f0f0f0, inset 3.01667px 3.01667px 0px #7e7e7e;
    border: none;
    height: 50px;
    padding: 0px 10px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: black;
    font-size: 20px;
    margin-bottom: 2px;
}
.field-style1::placeholder {
	color:rgb(158, 158, 158);
}

.custom-btn1 {
    background: #c3c3c3;
    box-shadow: inset -2.00833px -2.00833px 0px #262626,
      inset 1.00833px 1.00833px 0px #f0f0f0,
      inset -2.01667px -3.01667px 0px #7e7e7e;
    display: inline-block;
    padding: 2px 2px;
    margin: 5px;
    border: 1px solid black;
    text-decoration: underline;
    font-size: 20px;
    letter-spacing: 1px;
    float: right;
}
.custom-btn1 span {
    border: 1.1px dashed black;
    text-decoration: underline;
    padding: 2px 10px;
    display: inline-block;
    line-height: 25px;
    font-size: 20px;
}
.custom-btn2 {
    background: #c3c3c3;
    box-shadow: inset -2.00833px -2.00833px 0px #262626,
      inset 1.00833px 1.00833px 0px #f0f0f0,
      inset -2.01667px -3.01667px 0px #7e7e7e;
    display: inline-block;
    padding: 5px 5px;
    margin: 5px;
    border: 1px solid black;
    text-decoration: underline;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;
    
  }
  .custom-btn2 span {
    padding: 0px 20px;
    display: inline-block;
    text-decoration: underline;
    line-height: 25px;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;

  }
  .custom-btn3 {
    background: #c3c3c3;
    box-shadow: inset -2.00833px -2.00833px 0px #262626,
      inset 1.00833px 1.00833px 0px #f0f0f0,
      inset -2.01667px -3.01667px 0px #7e7e7e;
    display: inline-block;
    padding: 2px 2px;
    margin: 5px;
    border: 1px solid black;
    text-decoration: underline;
    font-size: 20px;
    letter-spacing: 1px;
}
.custom-btn3 span {
    border: 1.0px dashed black;
    text-decoration: underline;
    padding: 3px 10px;
    display: inline-block;
    line-height: 25px;
    font-size: 20px;
    letter-spacing: 1px;
}
.custom-btn4 {
    background: #c3c3c3;
    box-shadow: inset -2.00833px -2.00833px 0px #262626,
      inset 1.00833px 1.00833px 0px #f0f0f0,
      inset -2.01667px -3.01667px 0px #7e7e7e;
    display: inline-block;
    padding: 5px 5px;
    margin: 5px;
    border: 1px solid black;
    text-decoration: underline;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;
    
  }
  .custom-btn4 span {
    padding: 0px 20px;
    display: inline-block;
    text-decoration: underline;
    line-height: 25px;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;

  }
  .custom-btn4:hover span {
    padding: 0px 20px;
    display:none;
  }

  .custom-btn4:hover:before {
    content:"mint";
  }
  .custom-btn4:disabled:hover:before{
    content:"change chain";
  }
.custom-btn4:hover{
    background:linear-gradient(270deg, #1085D2 0%, #00007B 100%);
    color:white;
}

.custom-btn4:disabled:hover{
    background:linear-gradient(270deg, #d21010 0%, #7b001d 100%);
    color:white;
}




.custom-btn6 {
    background: #c3c3c3;
    box-shadow: inset -2.00833px -2.00833px 0px #262626,
      inset 1.00833px 1.00833px 0px #f0f0f0,
      inset -2.01667px -3.01667px 0px #7e7e7e;
    display: inline-block;
    padding: 5px 5px;
    margin: 5px;
    border: 1px solid black;
    text-decoration: underline;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;
    
  }
  .custom-btn6 span {
    padding: 0px 20px;
    display: inline-block;
    text-decoration: underline;
    line-height: 25px;
    font-size: 20px;
    letter-spacing: 1px;
    text-align: center;

  }
  .custom-btn6:hover{
    background:linear-gradient(270deg, #1085D2 0%, #00007B 100%);
    color:white;
}
.custom-btn6:disabled:hover{
    color: #7e7e7e;
    background: none;

}

.custom-btn6:disabled{
    border: 1px solid rgb(117, 117, 117);
    box-shadow: inset -2.00833px -2.00833px 0px #767676,
    inset 1.00833px 1.00833px 0px #f0f0f0,
    inset -2.01667px -3.01667px 0px #7e7e7e;

}

.custom-btn2:hover span {
    padding: 0px 20px;
    display:none;
  }
.custom-btn2:hover:before {
    content:"add/enable";
  }
  .custom-btn2:disabled:hover:before{
    content:"enabled";
  }
.custom-btn1:hover , .custom-btn2:hover , .custom-btn3:hover {
    background:linear-gradient(270deg, #1085D2 0%, #00007B 100%);
    color:white;
}
.custom-btn1:disabled, .custom-btn2:disabled{
    background:linear-gradient(270deg, #1085D2 0%, #00007B 100%);
    color:white;

}
.buttons-holder5 {
    display: block;
    flex-flow: column;
}
.buttons-holder5 .custom-btn2 {
    width: 45%;
    display: inline-block;

}

.buttons-holder5 .custom-btn4 {
    width: 45%;
    display: inline-block;

}

.sec-wid-left {
    flex: 0 0 60%;
    max-width: 60%;
}

.sec-wid-right {
    flex: 0 0 40%;
    max-width: 40%;
}

.step-form {
    float: left;
    width: 100%;
    padding: 0px 30px 0px 30px;
}

.step-form-data {
    float: left;
    padding: 15px 15px 15px 15px;
    width: 100%;
    background: #ffffff;
    box-shadow: inset 2.00833px 2.00833px 0px #262626, inset -1.00833px -1.00833px 0px #f0f0f0, inset 3.01667px 3.01667px 0px #7e7e7e;
}
.step-form-data-scores {
    float: left;
    padding: 15px 15px 15px 15px;
    width: 100%;
    background: #7c7c7c;
    box-shadow: inset 2.00833px 2.00833px 0px #262626, inset -1.00833px -1.00833px 0px #f0f0f0, inset 3.01667px 3.01667px 0px #7e7e7e;
}
label.checkbox-label1 {
    display: block;
    margin: 5px 10px 5px 0px;
    font-size: 20px;
    letter-spacing: 0.4px;
    position: relative;
    padding-left: 25px;
    cursor: finger;
}

.wrapper-2 {
    float: left;
    width: 100%;
    margin-top: 20px;
}

.page-content {
    float: left;
    width: 100%;
    
}

header {
    background: linear-gradient(180deg, #1085D2 1.56%, rgba(0, 0, 123, 0.77) 100%);
    padding: 5px 0px;
}

.logo {
    float: left;
}

.logo a {
    font-size: 20px;
    letter-spacing: 0.5px;
    display: inline-block;
}

.nav-actions {
    float: right;
    margin-top: 20px;
}


/*Media Queries Of Website*/
/*Mobile Devices*/
@media screen and (max-width:800px) and (min-width:320px) { 
    .sec-wid-left {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .sec-wid-right {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
}
